











import {Vue, Component} from "vue-property-decorator";
import {DistributionOrders} from "@/request/after-sale/DistributionOrders";

@Component({
    name: 'after_sale_distributionOrders'
})
export default class after_sale_distributionOrders extends Vue {
    modal: any = DistributionOrders;
    search: Array<any> = [
        {
            type: 'Input',
            label: '订单号',
            key: 'orderId',
            width: '140px',
            clearable: true,
            placeholder: '请填写电话'
        },
        {
            type: 'Input',
            label: '电话',
            key: 'userPhone',
            width: '140px',
            clearable: true,
            placeholder: '请填写电话'
        },
        {
            type: 'Select',
            label: '分销状态',
            key: 'distributionStatus',
            clearable: true,
            width: '120px',
            items: [
                {
                    label: '待入账',
                    value: 0
                },
                {
                    label: '已入账',
                    value: 1
                },
                {
                    label: '分佣取消',
                    value: 2
                }
            ]
        },
        {
            type: 'DatePicker',
            pickerType: 'daterange',
            format: 'yyyy-MM-dd',
            label: '提现时间',
            width: '210px',
            placeholder: '请选择注册时间'
        }
    ];
    columns: Array<any> = [
        {
            title: '订单号',
            key: 'orderId',
            minWidth: 200
        },
        {
            title: '分销状态',
            key: 'distributionStatus',
            minWidth: 120,
            render: (h: any, p: any) => h('p', p.row.distributionStatus===0?'待入账': p.row.distributionStatus===1?'已入账':p.row.distributionStatus===2?'分佣取消':'-')
        },
        {
            title: '订单总额',
            key: 'totalFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.totalFee))
        },
        {
            title: '实付金额',
            key: 'payFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.payFee))
        },
        {
            title: '可分配金额',
            key: 'distributionTotalFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.distributionTotalFee))
        },
        {
            title: '一级分销金额',
            key: 'firstUserFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.firstUserFee))
        },
        {
            title: '一级分销人',
            key: 'firstUserNickName',
            minWidth: 120
        },
        {
            title: '一级分销人电话',
            key: 'firstUserPhone',
            minWidth: 140
        },
        {
            title: '二级分销金额',
            key: 'secondUserFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.secondUserFee))
        },
        {
            title: '二级分销人',
            key: 'secondUserNickName',
            minWidth: 120
        },
        {
            title: '二级分销人电话',
            key: 'secondUserPhone',
            minWidth: 140
        },
        {
            title: '到账时间通知',
            key: 'arrivalAmountTime',
            minWidth: 180
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    created() {

    }
}

import { render, staticRenderFns } from "./DistributionOrders.vue?vue&type=template&id=10282c76&scoped=true&"
import script from "./DistributionOrders.vue?vue&type=script&lang=ts&"
export * from "./DistributionOrders.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10282c76",
  null
  
)

export default component.exports